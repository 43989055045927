const firebaseConfig = {
  apiKey: 'AIzaSyCqMpeTm3_2jPc0gbmDQvAYfSZQkEZsR9E',
  authDomain: 'uniben-marketplace.firebaseapp.com',
  projectId: 'uniben-marketplace',
  storageBucket: 'uniben-marketplace.appspot.com',
  messagingSenderId: '175686229375',
  appId: '1:175686229375:web:e78daee7440fdc2e554e4a',
  measurementId: 'G-0EGRRLLF23'
};
 
export default firebaseConfig;
